import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import Slider from "react-slick";
//import ComponentMain2 from "../../components/main/component-main-2";
//import ComponentTreatmentPnd from "../../components/main/component-treatment-pnd";
//import ComponentTextblock4 from "../../components/component-textblock-4";
import ComponentColumn2 from "../../components/component-column-2";
//import ComponentPics2 from "../../components/pics/component-pics-2";
//import {
//  Accordion,
//  AccordionItem,
//  AccordionItemHeading,
//  AccordionItemButton,
//  AccordionItemPanel,
//} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  //const settings = {
  //  dots: true,
  //  infinite: false,
  //  speed: 500,
  //  //autoplay: true,
  //  autoplaySpeed: 4000,
  //  arrows: false,
  //  slidesToShow: 1,
  //  //centerPadding: '100px',
  //  focusOnSelect: true,
  //  //centerMode: true,
  //  variableWidth: true,
  //  responsive: [
  //    {
  //      breakpoint: 896,
  //      settings: {
  //        centerMode: false,
  //      },
  //    },
  //  ],
  //};

  return (
    <Layout location={location} locale={intl.locale} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Mobility wellness - Promoting and improving seniors mobility`
            : `Bewegungswohlbefinden - Fördern der Mobilität von Senioren`
        }
        description={
          intl.locale === "en"
            ? `We help seniors stay fit! Try our revolutionary technology to help you sustain and improve our well-being.`
            : `Wir halten Senioren fit! Probieren Sie unsere revolutionäre Technologie, die Ihnen hilft Ihr Bewegungswohlbefinden zu erhalten und zu verbessern.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/tKVRgakvsbDZ8ByTyKUEU/853e3635494b919a36aeff48aa7f280f/E183.jpg?w=1200&h=630&fit=fill&f=faces"
      />

      <section className="sect sect_TreatmentCvd_first pt-0">
        <div className="main_back sect-bg-bls"></div>
        <div className="main_copy">
          <h1 className="h1 txt txt-white">
            {intl.locale === "en"
              ? `Promote Mobility Wellness`
              : `Unterstützung des Bewegungswohlbefinden`}
          </h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Start your well-being journey`
              : `Starten Sie die Reise des Wohlbefindens`}
          </p>
          <p className="txt txt-white">
            {intl.locale === "en"
              ? `Mobile wellness is the ability to move about without outside
  assistance. Mobility is integral to maintaining a high quality of
  life and independence, which is why we at Cyberdyne have dedicated
  countless hours towards developing technology that enhances overall
  well-being.`
              : `Bewegungswohlbefinden ist die Fähigkeit sich ohne externe Unterstützung bewegen zu können. Bewegungsfreiheit trägt wesentlich zu einer hohen Lebensqualität und Unabhängigkeit bei. Aus diesem Grund hat Cyberdyne viele Arbeitsstunden investiert und eine Technologie enwickelt die das allgemeine Wohlbefinden verbessert.`}
          </p>
        </div>
        <div className="main_img">
          <StaticImage
            src="../../images/training/elderly/treatment-headerimage@2x.png"
            alt="ImgTreatmentCatch"
            className="ImgHeader"
          />
        </div>
      </section>

      <section className="bg-white">
        <div className="container">
          <h2 className="message-lg">
            {intl.locale === "en"
              ? `At Cyberdyne, our mission is simple. We empower individuals to walk
  on their own using HAL, our non-invasive technology solution. Unlike
  other mobility therapies that simply guide users through the motions
  by completing movements for them, our technology actually retrains
  the nervous system on how to carry out the movement properly`
              : `Unserer Auftrag bei Cyberdyne ist einfach. Wir ermutigen Individuen eigenständig mit dem HAL, unsere nicht-invasive Technologielösung, zu Gehen. Im Gegensatz zu anderen Bewegungstherapien, die den Nutzer nur durch die Bewegung führen in dem sie die Bewegung für den Nutzer ausführen, trainiert unsere Technologie das Nervensystem wie Bewegungen richtig ausgeführt werden sollen.  `}
          </h2>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/training/elderly/14.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/training/elderly/15.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/training/elderly/cd20220307_S_143.jpg"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_third bg-white">
        <div className="container">
          <ComponentColumn2
            lefttitle={
              intl.locale === "en"
                ? `Helping individuals relearn how to carry out those movements by themselves. Our technology empowers individuals to walk on their own.`
                : `Individuen im Prozess des Wiedererlernens der richtigen Bewegungsausführung unterstützen. Unsere Technologie ermutigt Menschen eigenständig zu Gehen.`
            }
            leftcontent={
              intl.locale === "en"
                ? `One of HAL’s unique characteristics is its direct involvement with the neural signals generated by the wearer. Not only will the device display what signals are actually reaching the muscle, but it will also provide motion assistance based on these signals. With this internal information about what is happening inside the body when attempting to move, we can manipulate HAL’s response to these signals to encourage elderly wearers to use more appropriate muscle groups when carrying out daily activities, as well as produce stronger, healthier signals.<br />The synchronized movement of HAL also allows its wearers to repeat fitness enhancing movements with a wider range of motion and less load. This results in improved muscle flexibility, strength, and control of their movements.`
                : `Ein Alleinstellungsmerkmal von HAL ist, dass er die willkürlichen Signale des HAL-Trägers nutzt. HAL kann die Signale sowohl anzeigen als auch darauf basierend Bewegungsunterstützung zur Verfügung stellen. Mit der Information, was innerhalb des Körpers bei Bewegungen passiert, können die Einstellungen von HAL so angepasst werden, dass der HAL-Träger dazu ermutigt wird, die richtigen Muskeln besser für alltägliche Aufgaben einzusetzen und auch dazu, bessere und stärkere Signale zu erzeugen.`
            }
            leftcontent2={
              intl.locale === "en"
                ? `The synchronized movement of HAL also allows its wearers to repeat fitness enhancing movements with a wider range of motion and less load. This results in improved muscle flexibility, strength, and control of their movements. `
                : `Die synchrone Bewegung von HAL erlaubt es dem HAL-Träger, eigene Bewegungen über einen größeren Bewegungsradius und mit geringer Kraft auszuführen. Das Ergebnis sind eine verbesserte Muskelbeweglichkeit, Muskelkraft und Kontrolle über die Bewegungen.`
            }
            label=""
            list={
              intl.locale === "en"
                ? [
                    "Promote trunk extension activities against gravity",
                    "Improve sitting posture and sitting endurance",
                    "Improve sitting balance and stability",
                    "Promote proper movement of trunk center of gravity",
                    "Improve ability to stand up with increased stability",
                    "Improve balance while standing",
                    "Involve abdominal pressure",
                  ]
                : [
                    "Rumpfstreckung gegen die Erdanziehungskraft",
                    "Sitzposition und Sitzdauer",
                    "Gleichgewicht und der Stabilität im Sitzen",
                    "Richtige Verlagerung des Körperschwerpunkts",
                    "Aufstehen mit besserer Stabilität",
                    "Gleichgewicht während des Stehens",
                    "Nutzen des Abdominaldrucks",
                  ]
            }
          />
        </div>
      </section>

      <section className="sect position-relative overflow-visible">
        <StaticImage
          src="../../images/training/elderly/treatment-big-teaser@2x.png"
          class=""
        />

        <div className="floating-box">
          <div className="p-8 bg-rot">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Applications on Athletes`
                : `Anwendungen für Sportler`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `HAL helps top athletes learn how to relax their muscles through
  neurosensing technology. Being able to balance muscle contraction
  and relaxation is what separates the world’s top athletes from
  semi-professional athletes.`
                : `HAL hilft Sportlern mittels neurologischer Wahrnehmungstechnologie ihre Muskeln zu entspannen. Die Fähigkeit Muskelkontraktion und -entspannung im Gleichgewicht zu halten, unterscheidet professionelle Athleten von semi-professionellen Sportlern.`}
            </p>
            <div>
              <Link
                to="/training/athletes/"
                className="link-border link-border-white"
              >
                {intl.locale === "en" ? `Learn more` : `Erfahren Sie mehr`}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Your mobility journey starts here`
              : `Ihre Reise der Bewegung startet hier`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Find out how Cyberdyne can help.`
              : `Finden Sie heraus wie Cyberdyne Ihnen dabei hilft.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
